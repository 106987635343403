import { useEffect, useState, useContext } from "react";
import { validate } from "@utils";

import AdminContext from "@context";
import { Logout } from "@services";
import { actualizarAccesos } from "@services/afiliados";

const useEditar = onClose => {
  const {
    auth: { token, unique_code: unique_id },
    notify,
    setNotify,
  } = useContext(AdminContext);

  const [email, setEmail] = useState("");
  const [passwd, setPasswd] = useState("");
  const [passwdC, setPasswdC] = useState("");
  const [validStep, setValidStep] = useState(0);
  const [enableButton, setEnableButton] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [validp, validPasswd] = useState(false);

  useEffect(() => {
    setEnableButton(validate.mail(email).isValid && validp);
  }, [email, passwd, passwdC, validp]);

  useEffect(() => {
    const cont = validarPasswd();
    validPasswd(cont === 5);
  }, [passwdC]);

  const validarPasswd = () => {
    let cont = 0;
    if (passwdC.match(/[@$%&/()*+!]/)) {
      cont++;
    }
    if (passwdC.match(/[A-Z]/)) {
      cont++;
    }
    if (passwdC.match(/[a-z]/)) {
      cont++;
    }
    if (passwdC.match(/[0-9]/)) {
      cont++;
    }
    if (passwdC?.length >= 8) {
      cont++;
    }

    setValidStep(cont);
    return cont;
  };

  const onSave = () => {
    setRequesting(true);
    actualizarAccesos(
      {
        unique_id,
        email,
        passwd,
        passwdC,
      },
      token
    )
      .then(() => {
        onClose && onClose();
        setRequesting(false);
        setNotify({
          ...notify,
          success: true,
          message:
            "Se actualizaron tus datos correctamente, se cerrará tu sesion en unos momentos!",
        });

        Logout({ email, token }).then(() => {
          if (typeof window !== "undefined") {
            window.location.href = `${window.location.origin}/login`;
          }
        });
      })
      .catch(() => {
        onClose && onClose();
        setRequesting(false);
        setNotify({
          ...notify,
          error: true,
          message: "No pudimos actualizar tus datos correctamente",
        });
      });
  };

  return {
    onSave,
    email,
    setEmail,
    passwd,
    setPasswd,
    passwdC,
    setPasswdC,
    validStep,
    enableButton,
    validarPasswd,
    requesting,
  };
};

export default useEditar;
