import st, { css } from "styled-components";

const getOpacities = index => {
	const d = {
		0: css`
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				opacity: 0 !important;
			}
		`,
		1: css`
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				opacity: 0 !important;
			}
		`,
		2: css`
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				opacity: 0 !important;
			}
		`,
		3: css`
			&:nth-child(4),
			&:nth-child(5) {
				opacity: 0 !important;
			}
		`,
		4: css`
			&:nth-child(5) {
				opacity: 0 !important;
			}
		`,
	}

	return d[index];
}

const ValidSteps = st.div`
	height: 7px;
	width: 100%;
	border: 1px solid #e8edf2;
	border-radius: 2px;
	margin-top: 5px;
	display: flex;
	opacity: 1;
	transition: opacity 100ms ease-in-out 10ms;

	i {
		height: 5px;
		flex: 1;
		background: #1e826e;
		transition: opacity 150ms ease;

		&:nth-child(1) {
			opacity: 0.2;
		}
		&:nth-child(2) {
			opacity: 0.4;
		}
		&:nth-child(3) {
			opacity: 0.6;
		}
		&:nth-child(4) {
			opacity: 0.8;
		}
		&:nth-child(5) {
			opacity: 1;
		}

		${({step}) => getOpacities(+step)}
	}
`;

export { ValidSteps };
