import React from "react";
import { Flex, Col, Input, Select } from "@components";
import useAddress from "./hooks";

const Address = ({ defaultData, getValue }) => {
  const { data, update, updateCP, cp, colonias } = useAddress(defaultData, getValue);

  return (
    <Flex className="mt:2" justify="between">
      <Input
        capitalize
        value={data.country}
        getValue={v => update("country", v)}
        className="mb:1"
        placeholder="Pais"
      />
      <Input
        isNumber
        maxLength={5}
        value={cp}
        getValue={v => updateCP(v)}
        className="mb:1"
        placeholder="Codigo postal"
      />
      <Flex justify="between">
        <Col md={10}>
          <Input
            capitalize
            value={data.state}
            getValue={v => update("state", v)}
            className="mb:1"
            placeholder="Estado"
          />
        </Col>
        <Col md={9}>
          <Input
            capitalize
            value={data.city}
            getValue={v => update("city", v)}
            className="mb:1"
            placeholder="Ciudad"
          />
        </Col>
      </Flex>

      <Select
        getValue={v => update("localty", v)}
        placeholder="colonia"
        value={data.localty?.id}
        options={colonias}
      />

      <Input
        capitalize
        value={data.street}
        getValue={v => update("street", v)}
        className="mv:1"
        placeholder="Calle"
      />

      <Flex justify="between">
        <Col md={10}>
          <Input
            value={data.noExt}
            getValue={v => update("noExt", v)}
            className="mb:1"
            placeholder="Número Exterior"
          />
        </Col>
        <Col md={9}>
          <Input
            value={data.noInt}
            getValue={v => update("noInt", v)}
            className="mb:1"
            placeholder="Número Interior"
          />
        </Col>
      </Flex>
    </Flex>
  );
};

export default Address;
