import { useEffect, useState, useContext } from "react";
import defaultState from "./state";

import AdminContext from "@context";
import { getZipCode } from "@services";

const useAddress = (defaultData = null, getAddress) => {
	const { zipCodes, setZipCodes } = useContext(AdminContext);
	const [data, setData] = useState(defaultState);
	const [colonias, setColonias] = useState([]);
	const [cp, setCp] = useState('');

	useEffect(() => {
		if(defaultData) {
			const localty = colonias.filter((c) => c.label === defaultData.localty)[0];
			setCp(defaultData.postal);
			setData({
				...defaultData,
				localty
			});
		}
	}, [defaultData])

	const update = (key, value) => {
		if (key === "localty") {
			value = colonias.filter((c) => +c.id === +value)[0];
		}

		setData({
			...data,
			[key]: value,
		});
	};

	const updateCP = (postal) => {
		setCp(postal);
		if (postal?.length < 5) {
			setColonias([]);
			setData(defaultState);
		}
		if (postal?.length === 5) {
			getPostalCode(postal).then((address) => {
				setColonias(address.colonias);
				setData({ ...address, postal });
			});
		}
	};

	useEffect(() => {
		if (
			data.country &&
			data.postal?.length > 4 &&
			data.state &&
			data.city &&
			data.localty &&
			data.street &&
			data.noExt
		) {
			delete data?.colonias;
			getAddress && getAddress(data);
		}
	}, [data]);

	const getPostalCode = (cp) =>
		new Promise((resolve, reject) => {
			const current = zipCodes.filter((c) => +c.postal === +cp);

			if (current.length > 0) {
				resolve(current[0]);
				return;
			}

			getZipCode(cp)
				.then((address) => {
					setZipCodes([...zipCodes, address]);
					resolve(address);
				})
				.catch((error) => reject(error));
		});

	return {
		data,
		update,
		colonias,
		updateCP,
		cp
	};
};

export default useAddress;
