export default {
  name: '',
  lname: '',
  email: '',
  edad: '',
  birtdate: '',
  curp: '',
  rfc: '',
  unique_code: '',
  country: '',
  city: '',
  state: '',
  postalCode: '',
  delegacion: '',
  colonia: '',
  street: '',
  noInt: '',
  noExt: '',
  clabe: '',
  banco: '',
  langs: '',
  website: '',
  facebook: '',
  instagram: '',
  youtube: '',
  twitter: '',
  fingerprint: '',
  verified: '',
  active: '',
};
