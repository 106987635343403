export default {
	country: '',
	postal: '',
	state: '',
	city: '',
	localty: { id: undefined },
	street: '',
	noInt: '',
	noExt: ''
}
