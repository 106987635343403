import React from "react";
import {
  Modal,
  Flex,
  Col,
  SubTitle,
  Text,
  Svg,
  Input,
  Button,
} from "@components";

import { ValidSteps } from './styles'

import icon from "@assets/icons/key-blue.svg";
import useEditar from "./hooks";

const EditarAcceso = ({ show, onClose }) => {
  const {
    onSave,
    email,
    setEmail,
    passwd,
    setPasswd,
    passwdC,
    setPasswdC,
    validStep,
    enableButton,
		requesting
  } = useEditar(onClose);

  return (
    <Modal
      unClosed
      icon={icon}
      show={show}
      onClose={() => onClose()}
      title="Editar Accesos"
    >
      <Flex justify="center">
        <Col md={15} className="mb:15">
          <Text fw={300} fs={12} opacity={0.6} align="center" className="mv:1">
            Cuidado! estas por modificar tu información de acceso al sistema, te
            recomendamos realizar una copia de seguridad de tu datos para no
            olvidarlos más delante.
          </Text>

          <Input
            value={email}
            getValue={v => setEmail(v)}
            placeholder="Correo electrónico"
          />
        </Col>
        <Col md={15} className="mb:15">
          <Input
            type="password"
            value={passwd}
            getValue={v => setPasswd(v)}
            placeholder="Contraseña actual"
          />
        </Col>
        <Col md={15}>
          <Input
            type="password"
            value={passwdC}
            getValue={v => setPasswdC(v)}
            placeholder="Nueva contraseña"
          />
          <ValidSteps step={validStep}>
						<i></i>
						<i></i>
						<i></i>
						<i></i>
						<i></i>
					</ValidSteps>
        </Col>
      </Flex>

      <Flex className="mv:2" justify="center">
        <Col autofit className="ph:05">
          <Button disabled={requesting} square secondary onClick={() => onClose()}>
            Cancelar
          </Button>
        </Col>
        <Col autofit className="ph:05">
          <Button
						loading={requesting}
            disabled={!enableButton || requesting}
            square
            primary
            onClick={() => onSave()}
          >
            Guardar cambios
          </Button>
        </Col>
      </Flex>
    </Modal>
  );
};

export default EditarAcceso;
